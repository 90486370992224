import React from "react";

const Default = ({ props, children }) => {
  if (!props) {
    return null
  }

  const pageMeta = props.pageContext?.content?.page_meta;

  if (!pageMeta) {
    // console.warn("No pageMeta object found!", props);
  }

  return (
    <div>
      <div>{children}</div>
    </div>
  );
};

Default.displayName = "Default";

export default Default;
